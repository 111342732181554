import React, { forwardRef } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles, Grid, Paper, Divider
} from '@material-ui/core';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    height: '100%',
    paddingTop: 60,
    paddingBottom: 60
  },
  product: {
    position: 'relative',
    padding: theme.spacing(5, 3),
    cursor: 'pointer',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  productImage: {
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: -24,
    left: theme.spacing(3),
    height: 48,
    width: 48,
    fontSize: 24
  },
  recommendedProduct: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  chooseButton: {
    backgroundColor: theme.palette.common.white
  },
  fontTitle: {
    fontSize: '2rem',
  },
  underline: {
    maxWidth: '30%',
    margin: '-3% 35% 3% 35%',
  },
  trialBtn: {
    maxWidth: '40%',
    margin: '7% 30% 0% 30%',
    backgroundColor: '#EA5D94',
    color: "white"
  },
  centralBox: {
    paddingBottom: "50px"
  }
}));

const CTA: FC<any> = forwardRef(({ className, ...rest }, ref) => {
  const classes = useStyles();

  const handleClickPrice = (option) => {
    switch (option) {
      case 'Sin código':
        localStorage.setItem('planSelected', option);
        break;
      case 'Inicial':
        localStorage.setItem('planSelected', option);
        break;
      case 'Corporativo':
        localStorage.setItem('planSelected', option);
        break;
      case 'Premium':
        localStorage.setItem('planSelected', option);
        break;
      default:
        localStorage.setItem('planSelected', '');
        break;
    }
  }

  return (
    <div
      id="precios"
      ref={ref}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
          className={classes.fontTitle}
        >
          PRECIOS
        </Typography>
        <div className={classes.underline}>
          <img
            title="Arupo hub decoración borde rosa"
            alt="Arupo hub decoración borde rosa"
            src="/static/images/arupo_logos/SUBRAYADO_ROSA.svg"
          />
        </div>
        <Typography
          component="h5"
          variant="h3"
          align="center"
          color="textPrimary"
        >
          Escoge el plan que
        </Typography>
        <Typography
          component="h5"
          variant="h3"
          align="center"
          color="secondary"
        >
          Más se ajuste a tus necesidades
        </Typography>
        <Box mt="60px">
          <Container maxWidth="lg">
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                md={4}
                xs={12}
              >
                <Paper
                  className={classes.product}
                  elevation={1}
                >
                  <img
                    alt="Arupo Legaltech Innovation Hub Producto"
                    title="Arupo Legaltech Innovation Hub Producto"
                    className={classes.productImage}
                    src="/static/images/products/product_standard.svg"
                  />
                  <Typography
                    component="h6"
                    gutterBottom
                    variant="overline"
                    color="textSecondary"
                  >
                    Inicial
                  </Typography>
                  <div>
                    <Typography
                      component="span"
                      display="inline"
                      variant="h3"
                      color="textPrimary"
                    >
                      $25
                    </Typography>
                    <Typography
                      component="span"
                      display="inline"
                      variant="subtitle2"
                      color="textSecondary"
                    >
                      /mes
                    </Typography>
                  </div>
                  <Typography
                    variant="overline"
                    color="textSecondary"
                  >
                    10 documentos
                  </Typography>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                  >
                    Accesso ilimitado al "sandbox"
                    <br />
                    1 cuenta "creadora"
                    <br />
                    1 cuenta "operadora"
                    <br />
                    Hasta 500 correos electrónicos / mes
                    <br />
                    250 GB de almacenamiento
                    <br />
                    URL del documento automatizado compartible a terceros
                    <br />
                    Word add-in
                    <br />
                    Google Docs Add-on
                    <br />
                    Para personalización de acuerdo a tus necesidades, contáctanos
                  </Typography>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.chooseButton}
                    onClick={() => handleClickPrice("Inicial")}
                    component={RouterLink}
                    to="/register"
                  >
                    Seleccionar
                  </Button>
                </Paper>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <Paper
                  className={clsx(classes.product, classes.recommendedProduct)}
                  elevation={1}
                >
                  <img
                    alt="Arupo Legaltech Innovation Hub Producto"
                    title="Arupo Legaltech Innovation Hub Producto"
                    className={classes.productImage}
                    src="/static/images/products/product_premium--outlined.svg"
                  />
                  <Typography
                    component="h6"
                    gutterBottom
                    variant="overline"
                    color="inherit"
                  >
                    Corporativo
                  </Typography>
                  <div>
                    <Typography
                      component="span"
                      display="inline"
                      variant="h3"
                      color="inherit"
                    >
                      $78
                    </Typography>
                    <Typography
                      component="span"
                      display="inline"
                      variant="subtitle2"
                      color="inherit"
                    >
                      /mes
                    </Typography>
                  </div>
                  <Typography
                    variant="overline"
                    color="inherit"
                  >
                    30 documentos
                  </Typography>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <div className={classes.centralBox}>
                    <Typography
                      variant="body2"
                      color="inherit"
                    >
                      Accesso ilimitado al "sandbox"
                      <br />
                      1 cuenta "creadora"
                      <br />
                      3 cuentas "operadora"
                      <br />
                      Hasta 2500 correos electrónicos / mes
                      <br />
                      500 GB de almacenamiento
                      <br />
                      URL del documento automatizado compartible a terceros
                      <br />
                      Word add-in
                      <br />
                      Google Docs Add-on
                      <br />
                      Para personalización de acuerdo a tus necesidades, contáctanos
                    </Typography>
                  </div>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.chooseButton}
                    onClick={() => handleClickPrice("Corporativo")}
                    component={RouterLink}
                    to="/register"
                  >
                    Seleccionar
                  </Button>
                </Paper>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <Paper
                  className={classes.product}
                  elevation={1}
                >
                  <img
                    alt="Arupo Legaltech Innovation Hub Producto"
                    title="Arupo Legaltech Innovation Hub Producto"
                    className={classes.productImage}
                    src="/static/images/products/product_extended.svg"
                  />
                  <Typography
                    component="h6"
                    gutterBottom
                    variant="overline"
                    color="textSecondary"
                  >
                    Premium
                  </Typography>
                  <div>
                    <Typography
                      component="span"
                      display="inline"
                      variant="h3"
                      color="textPrimary"
                    >
                      &nbsp;
                    </Typography>
                    <Typography
                      component="span"
                      display="inline"
                      variant="subtitle2"
                      color="textSecondary"
                    >
                    </Typography>
                  </div>
                  &nbsp;
                  <Typography
                    variant="overline"
                    color="textSecondary"
                  >
                    Documentos ilimitados
                  </Typography>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                  >
                    Accesso ilimitado al "sandbox"
                    <br />
                    1 cuenta "creadora"
                    <br />
                    10 cuentas "operadoras"
                    <br />
                    Hasta 5000 correos electrónicos / mes
                    <br />
                    1 TB de almacenamiento
                    <br />
                    URL del documento automatizado compartible
                    <br />
                    Word add-in
                    <br />
                    Google Docs Add-on
                    <br />
                    Para personalización de acuerdo a tus necesidades contáctanos
                  </Typography>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  &nbsp;
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.chooseButton}
                    onClick={() => handleClickPrice("Premium")}
                    component={RouterLink}
                    to="/contacto/nosotros"
                  >
                    CONTÁCTANOS
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box mt={2}>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            className={classes.trialBtn}
            onClick={() => handleClickPrice("Sin código")}
            component={RouterLink}
            to="/register"
          >
            EXPLORADOR - GRATUITO
          </Button>
        </Box>
      </Container>
    </div>
  );
});

CTA.propTypes = {
  className: PropTypes.string
};

export default CTA;
